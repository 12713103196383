import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';

const Wrapper = styled('div', {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '80px 16px 16px 16px',
});

const WishlistAuthenticatedEmpty = ({ content }) => (
    <Wrapper>
        <OverlayContent content={content.wishlistAuthenticatedEmptyContent} />
        <ThemeButton to="/">Browse products</ThemeButton>
    </Wrapper>
);

WishlistAuthenticatedEmpty.propTypes = {
    content: PropTypes.object,
};

export default WishlistAuthenticatedEmpty;
